import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from '../../components/unauthorized/Login'
import LoginWithToken from '../../components/unauthorized/LoginWithToken'
import ForgotPassword from '../../components/unauthorized/ForgotPassword'
import ResetPassword from '../../components/unauthorized/ResetPassword'
import FlashMessage from '../../components/shared/FlashMessage'
import TokenExpired from '../../components/unauthorized/TokenExpired'
import UnauthContainer from '../UnauthContainer'
import SetupMFA from '../../components/unauthorized/mfa/SetupMFA'
import ConfirmSMS from '../../components/unauthorized/mfa/ConfirmSMS'
import ConfirmAuthenticator from '../../components/unauthorized/mfa/ConfirmAuthenticator'
import { useSearchParams } from '../../hooks/useSearchParams'

const UnauthRoutes = () => {
  const query = useSearchParams()
  const isI18nEnabled = query.get('enableLocalization') === '1'

  return (
    <UnauthContainer>
      <div>
        <FlashMessage key='unauth-flash' />
        <Switch key='unauth-switch'>
          <Route
            exact
            path='/login/:new_user_token'
            component={LoginWithToken}
          />
          <Route exact path='/login' children={<Login />} />
          <Route
            exact
            path='/login/token-expired/:new_user_token'
            component={TokenExpired}
          />
          <Route exact path='/forgot-password' children={<ForgotPassword />} />
          <Route
            exact
            path='/reset-password/:reset_password_token'
            children={<ResetPassword />}
          />
          <Route exact path='/mfa/setup' children={<SetupMFA />} />
          <Route exact path='/mfa/challenge/sms' children={<ConfirmSMS />} />
          <Route
            exact
            path='/mfa/challenge/rotating_token'
            children={<ConfirmAuthenticator />}
          />
          <Route
            render={() => (
              <Redirect
                to={{
                  pathname: '/login',
                  search: isI18nEnabled ? '?enableLocalization=1' : '',
                }}
              />
            )}
          />
        </Switch>
      </div>
    </UnauthContainer>
  )
}

export default UnauthRoutes
