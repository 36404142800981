import { combineReducers } from 'redux'
import { UNAUTHORIZED } from '../actions/Types'
import flash from './flashReducer'
import user from './UserReducer'
import dashboard from './dashboardReducer'
import Findings from './Findings'
import PdfServiceHelper from './PdfServiceHelper'
import displayBanners from './bannerReducer'
import mfa from '../slices/MfaSlice'

const appReducer = combineReducers({
  flash,
  user,
  dashboard,
  Findings,
  PdfServiceHelper,
  displayBanners,
  mfa,
})

const rootReducer = (state, action) => {
  if (action.type === UNAUTHORIZED) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
