export const defaultLanguage = {
  name: 'English',
  isoCode: 'en',
  localized: 'english',
  label: 'EN',
}

export const supportedLanguages = [
  {
    name: 'English',
    isoCode: 'en',
    localized: 'english',
    label: 'EN',
  },
  {
    name: 'Français',
    isoCode: 'fr_CA',
    localized: 'french',
    label: 'FR',
  },
]
