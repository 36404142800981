import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import classnames from 'classnames'
import {
  Container,
  Header,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCellHeader,
  TableCellData,
  ViewDetailsCell,
} from "../../../shared";
import "./DimensionSection.scss";
import { hostDimensionTranslation } from "../../../../helpers/functions";
import { useTranslation } from 'react-i18next'

const DimensionTableRowDetails = ({ isExpanded, dimension }) => {
  const { t } = useTranslation('private', {
    keyPrefix: 'dimensionSection',
  })

  if (dimension.value?.length < 1 && !dimension.notes) {
    return (
      <TableRow accordionChild expanded={isExpanded}>
        {isExpanded && (
          <TableCellData colSpan="3">
            <div className="dimension-details-container">
              {t('DimensionTableRowDetails.noDetails')}
            </div>
          </TableCellData>
        )}
      </TableRow>
    );
  }
  return (
    <TableRow accordionChild expanded={isExpanded}>
      {isExpanded && (
        <TableCellData colSpan="3">
          <div className="dimension-details-container">
            {dimension.value && (
              <div className="dimension-details">
                <p className="finding-details-label">Value</p>
                {dimension.value.map((value, idx) => (
                  <p key={`${value}-${idx}`} className="finding-details-value">
                    {value}
                  </p>
                ))}
              </div>
            )}
            {dimension.notes && (
              <div className="dimension-details">
                <p className="finding-details-label">{t('DimensionTableRowDetails.notes')}</p>
                <p className="finding-details-value">{dimension.notes}</p>
              </div>
            )}
          </div>
        </TableCellData>
      )}
    </TableRow>
  );
};

DimensionTableRowDetails.propTypes = {
  dimension: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

const DimensionTableRow = ({ dimension }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow
        accordionParent={!!dimension.value || !!dimension.notes}
        expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <TableCellData>
          {hostDimensionTranslation(dimension.dimension)}
        </TableCellData>
        <TableCellData
          className={classnames("dimension-rating", dimension.rating)}
        >
          {dimension.rating || "No Rating"}
        </TableCellData>
        <ViewDetailsCell expanded={isExpanded} />
      </TableRow>
      <DimensionTableRowDetails isExpanded={isExpanded} dimension={dimension} />
    </>
  );
};

DimensionTableRow.propTypes = {
  dimension: PropTypes.object.isRequired,
};

class DimensionSection extends Component {
  render() {
    const { finding } = this.props;

    return (
      <Container medium className="dimensions-section">
        <Header>Issue Dimensions</Header>
        <Table className="dimensions-table">
          <TableHead>
            <TableRow>
              <TableCellHeader>Dimension</TableCellHeader>
              <TableCellHeader>Rating</TableCellHeader>
              <TableCellHeader></TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {finding.dimensions.map((d) => (
              <DimensionTableRow key={d.dimension} dimension={d} />
            ))}
          </TableBody>
        </Table>
      </Container>
    );
  }
}

DimensionSection.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DimensionSection;
