import axios, { CancelToken } from 'axios'
import request from '../reference/axiosWrapper'
import { setFlash } from './flashActions'
import { domainCardKeyMappings, domainCardKeys, loadingDomainFindingsSkeleton } from '../constants/ActionPlan'
import { addIdToList } from './PdfServiceHelper'
import { pdfURL } from '../utils/environmentHelpers'
import { infoOnlyDomains } from '../constants/Report'
import prop from 'ramda/src/prop'
import dropRepeatsWith from 'ramda/src/dropRepeatsWith'
import eqBy from 'ramda/src/eqBy'
import pipe from 'ramda/src/pipe'
import reverse from 'ramda/src/reverse'
import { sortBy } from 'lodash'
import { parseISO, isAfter } from 'date-fns'
import { formatDate } from '../utils/functions'
import {
  FETCH_DOMAIN_FINDINGS_SUCCESS,
  FETCH_DOMAIN_FINDINGS_FAILURE,
  FETCH_CRITERIA_FINDINGS_SUCCESS,
  FETCH_CRITERIA_FINDINGS_FAILURE,
  FETCHING_DOMAIN_FINDINGS,
  FETCHING_CRITERIA_FINDINGS,
  FETCHING_OVERVIEW_OBJECT,
  FETCHING_TOE_SUMMARY,
  FETCH_OVERVIEW_ERROR,
  FETCH_OVERVIEW_TRENDS_SUCCESS,
  FETCH_TOE_SUMMARY_SUCCESS,
  FETCH_TOE_SUMMARY_FAILURE,
  PDF_SUCCESS,
  DOWNLOADING_PDF,
  RESET_DOMAIN_FINDINGS_PAGINATION,
  SET_SELECTED_TAB,
  SET_SELECTED_VALUES,
  SHOW_INLINE_FILTER,
  SET_SEARCH_OPTIONS,
  SET_FETCHING_OVERVIEW_TRENDS
} from './Types'
import { useAltTranslation as altT} from '../hooks/useAltTranslation'

const NODE_SERVICE_PREFIX = pdfURL(window.location.host)
const tPrefix = "dashboardActions";

let cancelDomainRequests = Object.assign({}, loadingDomainFindingsSkeleton)
let cancelCriteriaRequests = Object.assign({}, loadingDomainFindingsSkeleton)

// Action Creators

export const setSearchOptions = (searchOptions) => ({
  type: SET_SEARCH_OPTIONS,
  searchOptions
})
export const setShowInlineFilter = (show) => ({
  type: SHOW_INLINE_FILTER,
  show
})

export const setSelectedTab = (tab) => ({
  type: SET_SELECTED_TAB,
  tab
})
export const setSelectedValues = (values) => ({
  type: SET_SELECTED_VALUES,
  values
})
export const resetPagination = (changeStatus) => ({
  type: RESET_DOMAIN_FINDINGS_PAGINATION,
  changeStatus
})
export const fetchDomainFindingsSuccess = (response, domain) => ({
  type: FETCH_DOMAIN_FINDINGS_SUCCESS,
  data: response.data,
  links: response.links,
  meta: response.meta,
  domain: domain
})
export const fetchDomainFindingsFailure = (domain) => ({
  type: FETCH_DOMAIN_FINDINGS_FAILURE,
  domain: domain
})
export const fetchCriteriaFindingsSuccess = (response, criteria) => ({
  type: FETCH_CRITERIA_FINDINGS_SUCCESS,
  data: response.data,
  links: response.links,
  meta: response.meta,
  criteria: criteria
})
export const fetchCriteriaFindingsFailure = (criteria) => ({
  type: FETCH_CRITERIA_FINDINGS_FAILURE,
  criteria: criteria
})
export const fetchingDomainFindings = (domain) => ({
  type: FETCHING_DOMAIN_FINDINGS,
  domain: domain
})
export const fetchingCriteriaFindings = (criteria) => ({
  type: FETCHING_CRITERIA_FINDINGS,
  criteria: criteria
})
export const fetchingToeSummary = () => ({
  type: FETCHING_TOE_SUMMARY
})
export const fetchToeSummarySuccess = response => ({
  type: FETCH_TOE_SUMMARY_SUCCESS,
  data: response
})
export const fetchToeSummaryFailure = () => ({
  type: FETCH_TOE_SUMMARY_FAILURE
})
export const downloadingPdf = () => ({
  type: DOWNLOADING_PDF
})
export const pdfSuccess = () => ({
  type: PDF_SUCCESS
})

// Thunks
export function fetchAllFindings (customerId) {
  return async (dispatch) => {
    domainCardKeys.forEach((domainCriteriaKey) => {
      const { domain, criteria, expressionAttributes } = domainCardKeyMappings[domainCriteriaKey]
      if (criteria) {
        dispatch(fetchCriteriaFindings(customerId, domain, criteria, expressionAttributes))
      } else {
        dispatch(fetchDomainFindings(customerId, domain, criteria, expressionAttributes))
      }
    })
  }
}

export function downloadPdf (toe) {
  return async (dispatch) => {
    dispatch(setFlash([altT(tPrefix, 'downloadPdf')], 'info'))
    dispatch(downloadingPdf())
    try {
      const requestOptions = {
        method: 'POST',
        url: NODE_SERVICE_PREFIX + `pdf-service/vendor/${toe.id}/collaboration/generate-pdf`,
        data: {
          vendorName: toe.attributes.short_name
        },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': window.sessionStorage.getItem('accessToken')
        }
      }
      const response = await axios(requestOptions)
      let result = response.data
      let toeFileName = toe.attributes.short_name.replace(/\s+|\/+/g, '-')
      let idObj = {
        attempts: 20,
        toeFileName,
        id: result.pdf_id
      }
      dispatch(addIdToList(idObj, 'actionPlanPdf'))
    } catch (error) {
      console.log(error)
      dispatch(setFlash(['An error occurred, please try again.'], 'error'))
    }
  }
}

export function downloadPdfSuccess () {
  return async (dispatch) => {
    dispatch(pdfSuccess())
  }
}

export function fetchDomainFindings (customerIds, domain, criteria, page = 1, sortColumn = 'priority', sortDirection = 'asc', expressionAttributes, selectedTab) {
  return async (dispatch, getState) => {
    if (getState().dashboard.loadingDomainFindings[domain]) {
      cancelDomainRequests[domain]()
    }
    try {
      dispatch(fetchingDomainFindings(domain))
      const tid = Date.now() + Math.random()
      const requestOptions = {
        method: 'POST',
        url: `v2/vendor/findings?per_page=10&sort_method=${sortDirection}&page=${page}&sort_attribute=${sortColumn}&security_domain=${domain}&tid=${tid}&availability_state=${selectedTab}`,
        data: {
          security_domain: [domain],
          security_criteria: [criteria],
          expression_attributes: expressionAttributes,
          customer_ids: customerIds
        },
        cancelToken: new CancelToken(function executor (c) {
          cancelDomainRequests[domain] = c
        })
      }

      const success = await request(requestOptions)
      if (domain === 'web_encryption') {
        success.data.data.forEach(item => {
          if (item.attributes.finding_extra_data_value === '' && item.attributes.security_criteria_key === 'web_encryption_protocol') {
            item.attributes.finding_extra_data_value = item.attributes.finding_data_description
          }
          if (item.attributes.security_criteria_key === 'web_encryption_subject') {
            item.attributes.finding_data_value = item.attributes.finding_data_value.match(/CN=([^/]*)/)?.[1] || ''
          }
        })
      }
      dispatch(fetchDomainFindingsSuccess(success.data, domain))
    } catch (error) {
      console.log(error)
      dispatch(fetchDomainFindingsFailure(domain))
    }
  }
}

export function fetchCriteriaFindings (customerIds, domain, criteria, page = 1, sortColumn = 'priority', sortDirection = 'asc', expressionAttributes, selectedTab) {
  return async (dispatch, getState) => {
    if (getState().dashboard.loadingCriteriaFindings[criteria]) {
      cancelCriteriaRequests[criteria]()
    }
    const tid = Date.now() + Math.random()
    const requestOptions = {
      method: 'POST',
      url: `v2/vendor/findings?per_page=10&sort_method=${sortDirection}&page=${page}&sort_attribute=${sortColumn}&security_criteria=${criteria}&tid=${tid}&availability_state=${selectedTab}`,
      data: {
        security_domain: [domain],
        security_criteria: [criteria],
        expression_attributes: expressionAttributes,
        customer_ids: customerIds
      },
      cancelToken: new CancelToken(function executor (c) {
        cancelCriteriaRequests[criteria] = c
      })
    }
    dispatch(fetchingCriteriaFindings(criteria))
    try {
      request(requestOptions)
        .then(response => {
          if (criteria === 'email_encryption_enabled') {
            response.data.data.forEach(item => {
              item.attributes.domains_impacted = item.attributes.additional_info ? item.attributes.additional_info.length : null
              item.attributes.example_domain = item.attributes.additional_info ? item.attributes.additional_info.sort()[0] : null
            })
          }
          dispatch(fetchCriteriaFindingsSuccess(response.data, criteria))
        })
    } catch (error) {
      console.log(error)
      dispatch(fetchCriteriaFindingsFailure(criteria))
    }
  }
}

export function fetchOverviewTrends(toe) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_FETCHING_OVERVIEW_TRENDS, loadingRatingData: true})
      dispatch(fetchingObject('fetchingOverviewTrends'))
      const requestOptions = {
        method: 'GET',
        url: `v0/portfolio/member/${toe.attributes.current_analysis_id}.json`,
        header: {
          'Content-Type': 'application/json'
        }
      }
      const memberResponse = await request(requestOptions)

      const { toe_id: toeId } = memberResponse?.data?._embedded['rel:toes'][0]
      
      const requestOptionsDataLoss = {
        method: 'GET',
        url: `/v1/data_loss_events?toe_id=${toeId}`,
        header: {
          'Content-Type': 'application/json'
        }
      }

      const response = await request(requestOptionsDataLoss)
      const dataLossEvents = response.data.map(event => {
        const breachDate = event.breach_date
        const [month, year] = breachDate.split('/')

        return Object.assign({}, event, {
          breach_date: new Date(`${year}/${month}/1 `),
        })
      })
      const requestOptionsAnalysis = {
        method: 'GET',
        url: `/v0/analysis/overview/analyses_trend/${toe.attributes.current_analysis_id}`,
        header: {
          'Content-Type': 'application/json'
        }
      }
      const success = await request(requestOptionsAnalysis)
      dispatch({ type: SET_FETCHING_OVERVIEW_TRENDS, loadingRatingData: false})
      dispatch(fetchOverviewTrendsSuccess(success, dataLossEvents))
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}

function fetchingObject(fetchingVariable) {
  return {
    type: FETCHING_OVERVIEW_OBJECT,
    fetchingVariable,
  }
}

function fetchError(error) {
  console.error(error)
  return { type: FETCH_OVERVIEW_ERROR }
}

function fetchOverviewTrendsSuccess(response, dataLossEvents) {
  try {
    const processedOverviewTrends = Object.assign({}, response.data, {
      _embedded: Object.assign({}, response.data._embedded, {
        'rel:domain_ratings': response.data._embedded['rel:domain_ratings'].map(domainRating => {
          // modify info only security domains response to a standard.
          if (infoOnlyDomains.includes(domainRating.domain)) {
            domainRating.rating = -1
          }

          return Object.assign({}, domainRating)
        }),
      }),
    })

    let analysesTrends = sortBy(processedOverviewTrends._embedded['rel:analyses'], function(analysisTrend) {
      return analysisTrend.date_updated
    })

    analysesTrends = analysesTrends.map(function(analysisTrend) {
      const dateUpdated = analysisTrend?.date_updated?.split(' ')[0]
      const dateUpdatedISO = parseISO(dateUpdated)

      return Object.assign({}, analysisTrend, {
        date_updated: dateUpdatedISO,
      })
    })

    // dedupe same week analyses
    const dateString = d => formatDate(prop('date_updated', d), 'w-yyyy')
    const dedupedAnalysesTrends = pipe(
      reverse,
      dropRepeatsWith(eqBy(dateString)),
      reverse,
    )(analysesTrends)

    dataLossEvents.forEach(function(dataLossEvent) {
      dedupedAnalysesTrends.forEach(function(analysisTrend) {
        if (isAfter(analysisTrend.date_updated, dataLossEvent.breach_date) && dataLossEvent.date_updated === undefined) {
          dataLossEvent.date_updated = analysisTrend.date_updated
          dataLossEvent.overall_rating_numeric = analysisTrend.overall_rating_numeric
          analysisTrend.hasDataLossEvent = true
        }
      })
    })

    return {
      type: FETCH_OVERVIEW_TRENDS_SUCCESS,
      overviewTrends: processedOverviewTrends,
      analysisTrendsWithDataLoss: dedupedAnalysesTrends
      ,
    }
  } catch (error) {
    // dispatch(fetchError(error))
  }
}

export function fetchToeSummary () {
  return async (dispatch) => {
    const requestOptions = {
      method: 'GET',
      url: `v2/vendor/summary_info`
    }
    await dispatch(fetchingToeSummary(true))
    try {
      const success = await request(requestOptions)
      await dispatch(fetchToeSummarySuccess(success.data))
    } catch (error) {
      console.log(error)
      dispatch(fetchToeSummaryFailure(true))
    }
  }
}

export function setCustomerToggle (customer) {
  const { vendor_shared_with_customer, action_plan_id } = customer
  return async (dispatch) => {
    const requestOptions = {
      method: 'PUT',
      url: `v2/action_plan/${action_plan_id}`,
      data: {
        vendor_shared_with_customer: vendor_shared_with_customer
      }
    }
    await dispatch(fetchingToeSummary(true))
    try {
      await request(requestOptions)
        .then(() => dispatch(fetchToeSummary()))
    } catch (error) {
      console.log(error)
      dispatch(fetchToeSummary())
    }
  }
}
