import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from './shared/Header'
import Modal from './shared/Modal'
import './IntroductionModal.scss'
import request from '../reference/axiosWrapper'

const IntroductionModal = ({ setHasDismissedModal }) => {
  const { t } = useTranslation('private', {
    keyPrefix: 'introductionModal',
  })

  const handleDismissClick = async () => {
    try {
      const requestOptions = {
        method: 'PUT',
        url: `v2/users/save_event`,
        data: { identifier: 'dismissed-vendor-portal-intro' },
      }
      await request(requestOptions)
      setHasDismissedModal(true)
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <Modal
      close={() => setHasDismissedModal(true)}
      isOpen
      className='intro-modal'
    >
      <div className='intro-modal-content-wrapper'>
        <Header>{t('heading')}</Header>
        <p>{t('p1')}</p>
        <p>{t('p2')}</p>
        <div className='intro-modal-button-container'>
          <button onClick={() => handleDismissClick()}>
            {t('dontShowAgain')}
          </button>
          <button onClick={() => setHasDismissedModal(true)}>
            {t('dismiss')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default IntroductionModal
