import React from 'react'
import { truncateString, setClassColorByConcern, isIpAddress, defangUrl } from '../../helpers/TextHelpers'
import { criteriaValues } from '../../constants/ActionPlan'
import moment from 'moment'
import AddCircleSVG from '../shared/AddCircleSVG'
import KeyFindingSVG from '../shared/KeyFindingSVG'
import CancelSVG from '../shared/CancelSVG'
import CheckSVG from '../shared/CheckSVG'
import PendingScanSVG from '../shared/PendingScanSVG'
import Tooltip from '../shared/ToolTip'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'


function DomainFindingsRow (props) {
  const { finding, showFindingSidebarAsync, isCriteriaOnly } = props
  const criteriaAttributes = criteriaValues[finding.attributes.security_criteria_key]

  const { t } = useTranslation('private', {
    keyPrefix: 'domainFindingsRow',
  })

  const renderRowCell = (key, finding) => {
    const ipRegex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/
    const assetValueClick = (finding.attributes.security_criteria_key === 'dns_hijacking_protection' || finding.attributes.security_criteria_key === 'domain_hijacking_protection' || finding.attributes.security_criteria_key === 'email_authentication' || finding.attributes.security_criteria_key === 'email_encryption_enabled' || isIpAddress(finding.host_name))
    const isMaliciousCodeFinding = finding.attributes.security_criteria_key === 'malicious_code'

    const prettifyResponseStatus = {
      'no_action': 'No Action',
      'investigating': 'Investigating',
      'compensating_control': 'Compensating Control',
      'false_positive': 'False Positive',
      'not_fixing': 'Not Fixing',
      'fixing': 'Fixing',
      'fixed_resolved': 'Fixed',
      'fixed_not_resolved': 'Fixed',
      'fixed_pending_scan': 'Fixed'
    }


    const renderIcon = (key) => {
      switch (key) {
        case 'fixed_resolved':
          return <Tooltip title='<span>Resolved</span><br /><span>The latest scan identifies the issue as resolved.</span>'><CheckSVG fill='#27AE60' /></Tooltip>
        case 'fixed_not_resolved': {
          return <Tooltip title='<span>Not Resolved</span><br /><span>After you provided a status of Fixed, the latest scan still identifies the issue.</span>'><CancelSVG /></Tooltip>
        }
        case 'fixed_pending_scan': {
          return <Tooltip title='<span>Pending Scan</span><br /><span>There hasn&apos;t been a new scan since you provided the status of Fixed.</span>'><PendingScanSVG /></Tooltip>
        }
        default:
          return null
      }
    }
    switch (key) {
      case 'asset_value':
        if (assetValueClick) {
          return (
            <td key={key} className={`${key} ${setClassColorByConcern(key, finding.attributes[key])}`}>
              <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{finding.attributes[key] === 'key' ? <KeyFindingSVG /> : <div className='cell-value'>{finding.attributes[key]}</div>}</Tooltip>
            </td>
          )
        }
        return (
          <td key={key} className={`${key} ${setClassColorByConcern(key, finding.attributes[key])}`}>
            <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{finding.attributes[key] === 'key' ? <KeyFindingSVG /> : <div className='cell-value'>{finding.attributes[key]}</div>}</Tooltip>
          </td>
        )
      case 'severity':
        return <td key={key} className={`${key} ${setClassColorByConcern(key, finding.attributes[key])}`}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >
            <div className='cell-value'>{finding.attributes[key]}</div>
          </Tooltip>
        </td>
      case 'reporting_status':
        return <td key={key} className={`${key} ${finding.attributes[key]}`}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >
            <div className='cell-value'>{renderIcon(finding.attributes[key])} {<Tooltip title={prettifyResponseStatus[finding.attributes[key]]} disabled={(finding.attributes[key] && prettifyResponseStatus[finding.attributes[key]].length < 10)}>{truncateString(prettifyResponseStatus[finding.attributes[key]], 12)}</Tooltip>}</div>
          </Tooltip>
          </td>
      case 'priority':
        return (
          <td key={key} className={`${key} ${setClassColorByConcern(key, finding.attributes[key])}`} >
            <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} ><div className='cell-value'>{finding.attributes[key]}</div></Tooltip>
          </td>
        )
      case 'host_name':
        if (!ipRegex.test(finding.attributes[key]) && (finding.attributes.security_criteria_key !== 'web_threat_intel_alert_external') && finding.attributes.security_criteria_key !== 'email_encryption_enabled') {
          return (
            <td key={key} className={`${key}`}>
              <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >
              <div className='host-name'>
                {truncateString(finding.attributes[key], 40)}
              </div>
              </Tooltip>
            </td>
          )
        }
        return <td key={key} className={key}>{truncateString(finding.attributes[key], 40)}</td>
      case 'finding_extra_data_value':
        return (
          <td key={key} className={`${key}`}>
            <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{truncateString(finding.attributes[key], 40)}</Tooltip>
          </td>
        )
      case 'finding_data_value':
        if (!ipRegex.test(finding.attributes[key]) && (finding.attributes.security_criteria_key === 'config_web_cms_authentication' || isMaliciousCodeFinding)) {
          return (
            <td key={key} className={`${key}`}>
              <div className='host-name'>
                <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{isMaliciousCodeFinding ? truncateString(defangUrl(finding.attributes[key]), 40) : truncateString(finding.attributes[key], 40)}</Tooltip>
              </div>
            </td>
          )
        }
        if (finding.attributes.security_criteria_key === 'web_http_security_headers') {
          return (
            <td key={key}>
              <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >
              {finding.attributes[key] && finding.attributes[key].length > 0 && finding.attributes[key].split(',').map(d => <div key={d}>{d}</div>)}
              </Tooltip>
            </td>
          )
        }
        return <td key={key} className={key}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{truncateString(finding.attributes[key], 40)}</Tooltip>
        </td>
      case 'domain_name':
        return <td key={key} className={key}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{truncateString(finding.attributes[key], 40)}</Tooltip>
        </td>
      case 'first_seen':
        return <td key={key} className={key}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{moment(finding.attributes[key]).format('MM/DD/YYYY')}</Tooltip>
        </td>
      case 'last_seen':
        return <td key={key} className={key}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{moment(finding.attributes[key]).format('MM/DD/YYYY')}</Tooltip>
        </td>
      default:
        return <td key={key} className={key}>
          <Tooltip disabled={finding.attributes.found_in_customer_action_plans} title={t('disabledIssueTitle')} >{truncateString(finding.attributes[key], 40)}</Tooltip>
        </td>
    }
  }

  const handleRowClick = (event, inActionPlan) => {
    event.preventDefault()
    if (inActionPlan) {
      showFindingSidebarAsync(finding, false, 'issue', 'issue-collaboration', true, isCriteriaOnly)
    }
  }

  if (!finding.attributes.found_in_customer_action_plans) {
    return (
      <tr className={classnames('domain-findings-row', { notInCustomerPlan: !finding.attributes.found_in_customer_action_plans })} onClick={(event) => handleRowClick(event, finding.attributes.found_in_customer_action_plans)}>
        <td/>
        {criteriaAttributes && criteriaAttributes.length > 0 && criteriaAttributes.map(key => renderRowCell(key, finding))}
      </tr>

    )
  }

  return (
    <tr className={'domain-findings-row'} onClick={(event) => handleRowClick(event, finding.attributes.found_in_customer_action_plans)}>
      <td className='domain-findings-icon-td'><div className='domain-findings-row-circle'><AddCircleSVG size='20' /></div></td>
      {criteriaAttributes && criteriaAttributes.length > 0 && criteriaAttributes.map(key => renderRowCell(key, finding))}
    </tr>
  )
}

export default DomainFindingsRow
