import React from 'react'

const GlobeSVG = () =>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.66699 9.99999C1.66699 5.39999 5.39199 1.66666 9.99199 1.66666C14.6003 1.66666 18.3337 5.39999 18.3337 9.99999C18.3337 14.6 14.6003 18.3333 9.99199 18.3333C5.39199 18.3333 1.66699 14.6 1.66699 9.99999ZM13.3087 6.66666H15.767C14.967 5.29166 13.692 4.22499 12.1587 3.69999C12.6587 4.62499 13.042 5.62499 13.3087 6.66666ZM10.0003 3.36666C10.692 4.36666 11.2337 5.47499 11.592 6.66666H8.40866C8.76699 5.47499 9.30866 4.36666 10.0003 3.36666ZM3.33366 9.99999C3.33366 10.575 3.41699 11.1333 3.55033 11.6667H6.36699C6.30033 11.1167 6.25033 10.5667 6.25033 9.99999C6.25033 9.43332 6.30033 8.88332 6.36699 8.33332H3.55033C3.41699 8.86666 3.33366 9.42499 3.33366 9.99999ZM4.23366 13.3333H6.69199C6.95866 14.375 7.34199 15.375 7.84199 16.3C6.30866 15.775 5.03366 14.7167 4.23366 13.3333ZM4.23366 6.66666H6.69199C6.95866 5.62499 7.34199 4.62499 7.84199 3.69999C6.30866 4.22499 5.03366 5.28332 4.23366 6.66666ZM10.0003 16.6333C9.30866 15.6333 8.76699 14.525 8.40866 13.3333H11.592C11.2337 14.525 10.692 15.6333 10.0003 16.6333ZM7.91699 9.99999C7.91699 10.5667 7.97533 11.1167 8.05033 11.6667H11.9503C12.0253 11.1167 12.0837 10.5667 12.0837 9.99999C12.0837 9.43332 12.0253 8.87499 11.9503 8.33332H8.05033C7.97533 8.87499 7.91699 9.43332 7.91699 9.99999ZM12.1587 16.3C12.6587 15.375 13.042 14.375 13.3087 13.3333H15.767C14.967 14.7083 13.692 15.775 12.1587 16.3ZM13.7503 9.99999C13.7503 10.5667 13.7003 11.1167 13.6337 11.6667H16.4503C16.5837 11.1333 16.667 10.575 16.667 9.99999C16.667 9.42499 16.5837 8.86666 16.4503 8.33332H13.6337C13.7003 8.88332 13.7503 9.43332 13.7503 9.99999Z"
              fill="#435A70"/>
    </svg>


export default GlobeSVG
