import React from 'react'
// import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthContainer from '../AuthContainer'
import Dashboard from '../../components/dashboard/Dashboard'
import TwoStepVerification from '../../components/twoStepVerification/TwoStepVerification'
import { useSearchParams } from '../../hooks/useSearchParams'

// Import Dashboard component

const AuthRoutes = () => {
  const query = useSearchParams()
  const isI18nEnabled = query.get('enableLocalization') === '1'

  return (
    <AuthContainer>
      <>
        <Switch key='auth-switch'>
          <Route exact path='/dashboard' children={<Dashboard />} />
          <Route
            path='/multi-factor-verification'
            children={<TwoStepVerification />}
          />
          <Route
            render={() => (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  search: isI18nEnabled ? '?enableLocalization=1' : '',
                }}
              />
            )}
          />
        </Switch>
      </>
    </AuthContainer>
  )
}

export default AuthRoutes
