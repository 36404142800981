import axios from 'axios'
import jwt_decode from 'jwt-decode'
import store from '../store'
import { UNAUTHORIZED } from '../actions/Types'
import { authURL } from '../utils/environmentHelpers'

const AUTH_PREFIX = authURL(window.location.host)

const getAccessToken = () => {
  try {
    return window.sessionStorage.getItem('accessToken')
  } catch (e) {
    return null
  }
}

const getRefreshToken = () => {
  try {
    return window.sessionStorage.getItem('refreshToken')
  } catch (e) {
    return null
  }
}

const request = (requestOptions, apiUrl = AUTH_PREFIX) => {
  const failWithoutToken = requestOptions?.failWithoutToken === false ? false : true
  function makeRequest (resolve, reject) {
    const headers = {
      ...requestOptions.headers,
      'Riskrecon-App-Id': 'vendor-portal'
    };

    const token = getAccessToken()
    if (token) {
      headers['Authorization'] =  `Bearer ${token}`
    }

    const injectedOptions = {
      ...requestOptions,
      url: apiUrl + requestOptions.url,
      headers: headers
    }
    axios(injectedOptions)
      .then(res => resolve(res))
      .catch(res => reject(res))
  }

  return new Promise((resolve, reject) => {
    const tokenError = new Error('Error in getting token.');
    const maybeReject = (err) => {
      if (err === tokenError && failWithoutToken) {
        reject(err)
      }
      makeRequest(resolve, reject)
    }
    const accessToken = getAccessToken()
    if (accessToken === null ) { maybeReject(tokenError) } // dispatch unauthorized to logout?
    const decodedAccessToken = jwt_decode(accessToken)
    if (decodedAccessToken.exp - 180 < Math.floor(Date.now() / 1000)) {
      const refresh_token = getRefreshToken()
      const { user_id } = decodedAccessToken.user
      const data = {
        grant_type: 'refresh_token',
        refresh_token,
        user_id
      }
      const requestOptions = {
        method: 'POST',
        url: AUTH_PREFIX + 'v2/oauth/token',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
      }
      axios(requestOptions)
        .then(res => {
          window.sessionStorage.setItem('accessToken', res.data?.access_token)
          window.sessionStorage.setItem('refreshToken', res.data?.refresh_token)
          makeRequest(resolve, reject)
        })
        .catch(res => {
          window.sessionStorage.removeItem('accessToken')
          window.sessionStorage.removeItem('refreshToken')
          // this could probably be more graceful. will get some console errors.
          store.dispatch({ type: UNAUTHORIZED })
          reject(res)
        })
    } else {
      makeRequest(resolve, reject)
    }
  })
}

export default request
