import React from 'react'
import LoadingIcon from '../loaders/LoaderIcon'
import PendingScanSVG from '../shared/PendingScanSVG'
import CheckSVG from '../shared/CheckSVG'
import CancelSVG from '../shared/CancelSVG'
import Tooltip from '../shared/ToolTip'
import { useTranslation } from 'react-i18next'

const IssueStatusSummary = React.memo(function IssueStatusSummary(props) {
  const { toeSummary, fetchingToeSummary } = props
  const {
    fixed_not_resolved: fixedNotResolved,
    fixed_pending_scan: fixedPendingScan,
    fixed_resolved: fixedResolved,
  } = toeSummary.resolved_findings_counts || {}

  const { t } = useTranslation('private', {
    keyPrefix: 'issueStatusSummary',
  })

  const renderFixedStatus = (k) => (
    <>
      <Tooltip
        className='issue-status-tooltip'
        title={`<span>${t('tooltips.notResolvedHeader')}</span><br /><span>${t(
          'tooltips.notResolvedBody'
        )}</span>`}
      >
        <div className='fixed-not-resolved'>
          <CancelSVG /> {fixedNotResolved}
        </div>
      </Tooltip>
      <Tooltip
        className='issue-status-tooltip'
        title={`<span>${t('tooltips.pendingHeader')}</span><br /><span>${t(
          'tooltips.pendingBody'
        )}</span>`}
      >
        <div className='fixed-pending-scan'>
          <PendingScanSVG /> {fixedPendingScan}
        </div>
      </Tooltip>
      <Tooltip
        className='issue-status-tooltip'
        title={`<span>${t('tooltips.resolvedHeader')}</span><br /><span>${t(
          'tooltips.resolvedBody'
        )}</span>`}
      >
        <div className='fixed-resolved'>
          <CheckSVG /> {fixedResolved}
        </div>
      </Tooltip>
    </>
  )

  const WidgetStatusRows = Object.keys(toeSummary.issue_categories_counts).map(
    (k, index) => (
      <div key={index} className='widget-stats-row'>
        <span>{t(`statuses.${k}`)}</span>
        {k === 'fixed' ? (
          <div className='fixed-row'>
            {renderFixedStatus(k)}{' '}
            <span>{toeSummary.issue_categories_counts[k]}</span>
          </div>
        ) : (
          <span className='stats-count'>
            {toeSummary.issue_categories_counts[k]}
          </span>
        )}
      </div>
    )
  )

  if (fetchingToeSummary) {
    return <LoadingIcon minHeight='220' />
  }

  return (
    <div>
      <div className='widget-count centered'>
        {toeSummary.open_issues_count}
      </div>
      <div className='widget-subheading centered'>{t('sharedIssues')}</div>
      <div className='widget-stats-wrapper'>
        <div className='widget-stats-row heading'>
          <span>{t('status')}</span>
          <span>{t('issues')}</span>
        </div>
        {WidgetStatusRows}
      </div>
    </div>
  )
})

export default IssueStatusSummary
