import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LanguageDropdown } from '../components/LanguageDropdown'

function UnauthContainer(props) {
  const { t } = useTranslation('public', {
    keyPrefix: 'unauthContainer',
  })

  return (
    <div id='unauth-wrapper'>
      <header className='unauth-header-container'>
        <div className='unauth-header-div'>
          <div className='unauth-logo' />
          <section className='unauth-i18n'>
            <LanguageDropdown />
          </section>
        </div>
      </header>
      {props.children}
      <footer id='portal-footer'>
        <div className='content-container'>
          {t('disclaimer', { date: new Date().getFullYear() })}
        </div>
      </footer>
    </div>
  )
}

UnauthContainer.propTypes = {
  children: PropTypes.element,
}

export default UnauthContainer
