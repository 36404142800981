import { Component } from 'react';
import { api as axios } from './xhr';
import { connect } from 'react-redux';
import { updateIdLists, failedToGetFile, succeededToGetFile } from '../actions/PdfServiceHelper'
import { pdfURL } from '../utils/environmentHelpers'
import { useAltTranslation as altT} from '../hooks/useAltTranslation'

const pdfServiceUrl = pdfURL(window.location.host);
const tPrefix = "pdfServiceHelper";


class PdfServiceHelper extends Component {
  state = {
    checkTime: 1000 * 20, //check once every 20 seconds
  }

  componentDidMount() {
    const { checkTime } = this.state;
    setTimeout(this.checkForDownloads, checkTime);
  }

  componentWillUnmount(){
    this.props.updateIdLists([]); //clear lists out
  }

  downloadFiles = async () => {
    const {vendorActionPlanPdfIdObjects} = this.props.pdfServiceHelper;
    // action plan pdfs
    let vendorActionPlanPdfIdObjectsToTryAgain = [];
    for(let idObj of vendorActionPlanPdfIdObjects){
      let newIdObj = {
        ...idObj,
        attempts: idObj.attempts - 1
      };
      let result = await this.downloadAFile(newIdObj.id, newIdObj.toeFileName, 'actionPlanPdf');
      if(!result && newIdObj.attempts > 0){
        vendorActionPlanPdfIdObjectsToTryAgain.push(newIdObj);
      }else if(!result && newIdObj.attempts === 0){
        this.props.failedToGetFile(altT(tPrefix, 'downloadPdfError'));
      }else if(result){
        this.props.succeededToGetFile(altT(tPrefix, 'downloadPdfOk'));
      }
    }
    this.props.updateIdLists(vendorActionPlanPdfIdObjectsToTryAgain);
  }

  downloadAFile = async (fileName, vendorName, type) => {
    let data = null;
    try{
      data = await this.getFileFromPdfService(fileName, type, vendorName);
    }catch(err){
      //file not yet on s3
      return false;
    }
    if(data === null){
      return false;
    }
    const blob = new Blob([data], {type: 'application/pdf'});
    //for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
      return true;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    return true;
  }

  getFileFromPdfService = async (fileName, type, vendorName) => {
    let url;
    fileName = encodeURIComponent(fileName);
    vendorName = encodeURIComponent(vendorName);
    switch(type){
      case 'actionPlanPdf':
        url = `${pdfServiceUrl}pdf-service/vendor/collaboration/retrieve-pdf?fileName=${fileName}&vendorName=${vendorName}`;
        break;
      default:
        throw new Error(['Bad type used ' + type]);
    }
    let acceptType = 'application/pdf';
    const response = await axios.get(
      url,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': acceptType,
          'Authorization' : window.sessionStorage.getItem('accessToken'),
        }
      }
    );

    return response.data;
  }

  checkForDownloads = async () => {
    const {vendorActionPlanPdfIdObjects} = this.props.pdfServiceHelper;
    const {checkTime} = this.state;
    if(vendorActionPlanPdfIdObjects.length !== 0){
      await this.downloadFiles();
    }
    setTimeout(this.checkForDownloads, checkTime);
  }



  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  pdfServiceHelper: state.PdfServiceHelper
});

export default connect(mapStateToProps, {
  updateIdLists,
  failedToGetFile,
  succeededToGetFile
})(PdfServiceHelper);
