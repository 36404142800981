import React, { useEffect } from 'react'
import { logout } from '../actions/UserActions'
import { setFlash } from '../actions/flashActions'
import { connect, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import FlashMessage from '../components/shared/FlashMessage'
import { downloadPdf, fetchToeSummary } from '../actions/dashboardActions'
import { resetAndCloseSidebarFindings } from '../actions/Findings'
import SideDrawer from '../components/shared/SideDrawer'
import FindingSidebar from '../containers/FindingSidebar'
import { IdleTimerProvider } from 'react-idle-timer'
import PdfServiceHelper from '../helpers/PdfServiceHelper'
import { NavItemDropDown } from '../components/NavItemDropDown'
import FilterFindings from '../components/dashboard/FilterFindings'
import { LanguageDropdown } from '../components/LanguageDropdown'
import { useTranslation } from 'react-i18next'
import LoadingIcon from '../components/loaders/LoaderIcon'

const AuthContainer = ({
  children,
  findingDrawerOpen,
  resetAndCloseSidebarFindings,
  toe,
  fetchToeSummary,
  fetchingToeSummary,
  downloadingPdf,
  showInlineFilter,
  logout,
  setFlash,
  downloadPdf,
}) => {
  const { pathname } = useLocation()
  const { sso_enabled } = useSelector((state) => state.mfa)

  useEffect(() => {
    const fetchToeSummaryOnMount = async () => {
      await fetchToeSummary()
    }
    fetchToeSummaryOnMount()
    // eslint-disable-next-line
  }, [])

  const { i18n, t } = useTranslation('private', {
    keyPrefix: 'authContainer',
  })

  useEffect(() => {
    if (!i18n.hasLoadedNamespace('private')) i18n.loadNamespaces('private')
  }, [])

  if (!i18n.hasLoadedNamespace('private'))
    return <LoadingIcon minHeight='220' />

  const onLogout = () => {
    logout()
  }

  const onIdle = (e) => {
    logout()
    setFlash(['You have been logged out due to inactivity.'], 'warning')
  }

  return (
    <div id='auth-container'>
      <PdfServiceHelper />
      <IdleTimerProvider
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 20}
      />
      <div id='portal-container'>
        <header id='portal-header'>
          {pathname.includes('/dashboard') && (
            <>
              <div className='horizontal-divider' />
              {!showInlineFilter && (
                <div className='horizontal-divider-filter' />
              )}
            </>
          )}
          <div className='content-container clearfix'>
            <div>
              <NavLink to='/dashboard'>
                <img
                  className='logo'
                  src={require('../assets/images/rr-logo.png')}
                  alt='RiskRecon'
                />
              </NavLink>
              <nav className='flex justify-flex-end'>
                <NavItemDropDown title={t('navTitle')}>
                  {!pathname.includes('/dashboard') && (
                    <NavLink to='/dashboard' className='hover-nav'>
                      {t('navDashboard')}
                    </NavLink>
                  )}
                  <NavLink to='/eula' target='_blank' className='hover-nav'>
                    {t('navEula')}
                  </NavLink>
                  {!sso_enabled && (
                    <NavLink
                      to='/multi-factor-verification'
                      className='hover-nav'
                    >
                      {t('navMfa')}
                    </NavLink>
                  )}
                  <NavLink
                    to={{ pathname: 'https://youtu.be/IGcxUxerBhA' }}
                    target='_blank'
                    className='hover-nav'
                  >
                    {t('navDemo')}
                  </NavLink>
                  <button onClick={onLogout} className='hover-nav'>
                    {t('navLogout')}
                  </button>
                </NavItemDropDown>
                <LanguageDropdown />
              </nav>
            </div>
            {pathname === '/dashboard' && (
              <div className='dashboard-heading'>
                <div className='dashboard-heading__left'>
                  <h1 className='page-heading'>{toe.attributes.short_name}</h1>
                  {showInlineFilter && (
                    <h3 className='page-sub-heading'>{t('pageSubHeading')}</h3>
                  )}
                </div>
                <div className='dashboard-heading__right'>
                  <button
                    style={downloadingPdf ? { width: '150px' } : null}
                    className='btn btn--blue-outline btn--pdf btn--download-full-report clickable'
                    disabled={downloadingPdf || fetchingToeSummary}
                    onClick={fetchingToeSummary ? null : () => downloadPdf(toe)}
                  >
                    {downloadingPdf || fetchingToeSummary ? (
                      <img
                        src={require('../assets/images/btn-icon-pdf-disabled.png')}
                        alt=''
                      />
                    ) : (
                      <img
                        src={require('../assets/images/btn-icon-pdf.png')}
                        alt=''
                      />
                    )}
                    {downloadingPdf ? t('pdfGenerate') : t('pdfDownload')}
                  </button>
                </div>
              </div>
            )}
            {!showInlineFilter && <FilterFindings />}
          </div>
        </header>
        <div
          className={`content-container ${
            pathname.includes('multi-factor-verification') &&
            'mfa-settings-container'
          }`}
        >
          <FlashMessage key='auth-flash' />
          {children}
        </div>
        <SideDrawer
          open={findingDrawerOpen}
          closeDrawer={resetAndCloseSidebarFindings}
        >
          {findingDrawerOpen && <FindingSidebar />}
        </SideDrawer>
      </div>
      <footer id='portal-footer'>
        <div className='content-container'>
          {t('disclaimer', { date: new Date().getFullYear() })}
        </div>
      </footer>
    </div>
  )
}

AuthContainer.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.element,
  logout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    findingDrawerOpen: state.Findings.findingDrawerOpen,
    toe: state.dashboard.toe.data,
    fetchingToeSummary: state.dashboard.fetchingToeSummary,
    downloadingPdf: state.dashboard.downloadingPdf,
    showInlineFilter: state.dashboard.showInlineFilter,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetAndCloseSidebarFindings: () => dispatch(resetAndCloseSidebarFindings()),
  fetchToeSummary: () => dispatch(fetchToeSummary()),
  logout: () => dispatch(logout()),
  setFlash: (message, kind) => dispatch(setFlash(message, kind)),
  downloadPdf: (toe) => dispatch(downloadPdf(toe)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)
