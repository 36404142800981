import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { authURL } from './utils/environmentHelpers'
import {
  defaultLanguage,
  supportedLanguages,
} from './constants/SupportedLanguages'
import axios from 'axios'

const API_HOST = authURL(window.location.host)

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage.isoCode,
    ns: ['public'],
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    supportedLanguages: supportedLanguages.map((lang) => lang.isoCode),
    transSupportBasicHtmlNodes: true,
    returnEmptyString: false,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
      ],
    },
    backend: {
      crossDomain: true,
      withCredentials: true,
      loadPath: `api/i18n/vendor_portal/{{ns}}/{{lng}}`,
      request: function (options, url, payload, callback) {
        const token = window.sessionStorage.getItem('accessToken')

        const requestOptions = {
          url: API_HOST + url,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        if (token) requestOptions.headers['Authorization'] = token

        axios(requestOptions)
          .then(({ data }) => {
            callback(null, { status: 'ok', data })
          })
          .catch((e) => {
            console.error('catched', e)
            callback(null, null)
          })
      },
    },
    react: {
      useSuspense: false,
    },
  })
  .then((i) => {
    return i
  })

export default i18n
