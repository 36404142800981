import React from "react";
import RiskRecon_Banner from "../images/RiskRecon_Banner.png";
import "./rr_banner.scss";
import { useTranslation, Trans } from 'react-i18next'

const RR_banner = (props) => {
  const { t } = useTranslation('private', {
    keyPrefix: 'banners',
  })
  
  return (
    <div
      className="widget-card-banner-rr-banner"
      style={{
        backgroundImage: `url(${RiskRecon_Banner})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <h1>{t('riskRecon.heading')}</h1>
      <p dangerouslySetInnerHTML={{ __html: t('riskRecon.p1') }}/>
      <p dangerouslySetInnerHTML={{ __html: t('riskRecon.p2') }}/>
      <div
        className="rr-button"
        onClick={() =>
          window.open("https://www.riskrecon.com/vendor-portal", "_blank")
        }
      >
        <label>{t('riskRecon.requestReport')}</label>
      </div>
    </div>
  );
};

export default RR_banner;
