/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import EulaAcceptModal from '../components/EulaAcceptModal'
import { setUserLoggedIn } from '../actions/UserActions'
import { useDispatch, useSelector } from 'react-redux'
import request from '../reference/axiosWrapper'
import { SET_USER_INFO, RESET_LOGIN } from '../actions/Types'
import IntroductionModal from '../components/IntroductionModal'
import { setShowInlineFilter } from '../actions/dashboardActions'
import debounce from 'lodash/debounce'
import AuthRoutes from './routes/AuthRoutes'
import UnauthRoutes from './routes/UnauthRoutes'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { setUserMfa } from '../slices/MfaSlice'
import LoaderIcon from '../components/loaders/LoaderIcon'
import { authURL, portalEnv } from '../utils/environmentHelpers'
import { FullStory } from '@fullstory/browser'
import { useSearchParams } from '../hooks/useSearchParams'

const App = () => {
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [hasDismissedModal, setHasDismissedModal] = useState(false)
  const [modalOpen, setModalOpen] = useState(true)
  const currentEnv = portalEnv(window.location?.host)

  const history = useHistory()
  const query = useSearchParams()
  const dispatch = useDispatch()

  const {
    loggedIn,
    data: { user_id },
    userInfo: {
      has_signed_current_eula,
      dismissed_vendor_portal_intro,
      user_email,
      customer_id,
      customer_name,
    },
  } = useSelector((state) => state.user)
  const { route_to_mfa, mfa_route } = useSelector((state) => state.mfa)

  const isI18nEnabled = query.get('enableLocalization') === '1'
  const i18nSearch = isI18nEnabled ? '?enableLocalization=1' : ''

  useEffect(() => {
    // keep track of all non-login routes for refresh capability
    if (history.location.pathname !== '/login') {
      window.sessionStorage.setItem(
        'lastRoute',
        `${history.location.pathname}${i18nSearch}`
      )
    }
  }, [history.location.pathname])

  useEffect(() => {
    if (route_to_mfa && mfa_route && !loggedIn) {
      return history.push(`${mfa_route}`)
    }
  }, [route_to_mfa, mfa_route, loggedIn])

  const checkStorage = async () => {
    try {
      const accessToken = window.sessionStorage.getItem('accessToken')
      // Certain unauth routes should not be redirected,
      // Including the /login/:new_user_token route
      const validUnauthRoutes = ['reset-password', 'login']
      const shouldRedirect =
        !accessToken &&
        !validUnauthRoutes?.some((route) =>
          history.location.pathname?.includes(route)
        )
      if (shouldRedirect) return history.push(`/login${i18nSearch}`)
      if (accessToken) {
        const lastRoute = window.sessionStorage.getItem('lastRoute')
        const decodedToken = jwt_decode(accessToken)
        if (decodedToken.exp > Math.floor(Date.now() / 1000)) {
          const {
            authy_id,
            authy_verified_at,
            configured_mfa_channel,
            last_mfa_strategy,
            enforce_mfa,
            sso_enabled,
            mfa_enabled,
          } = decodedToken.user
          const userMfa = {
            authy_id,
            authy_verified_at,
            configured_mfa_channel,
            last_mfa_strategy,
            enforce_mfa,
            sso_enabled,
            mfa_enabled,
          }
          await dispatch(setUserLoggedIn(decodedToken.user))
          await dispatch(setUserMfa(userMfa))

          let route = ''
          // Make sure i18n feature sticks/unsticks when queryParam is set/gone
          if (lastRoute) {
            if (isI18nEnabled && !lastRoute.includes('enableLocalization')) {
              route = `${lastRoute}${i18nSearch}`
            } else if (
              !isI18nEnabled &&
              lastRoute.includes('enableLocalization')
            ) {
              route = lastRoute.substring(0, lastRoute.indexOf('?'))
            } else {
              route = lastRoute
            }
          }

          return lastRoute
            ? history.push(`${route}`)
            : history.push(`/dashboard${i18nSearch}`)
        }
      }
    } catch (err) {
      console.log(err, 'error checking access token')
    }
    setLoading(false)
  }

  const handleScroll = () => {
    const portalHeader = document.getElementById('portal-header')
    if (window.pageYOffset > 480) {
      dispatch(setShowInlineFilter(false))
      portalHeader.classList.add('sticky')
    } else {
      dispatch(setShowInlineFilter(true))
      portalHeader.classList.remove('sticky')
    }
  }

  const debouncedHandleScroll = debounce(handleScroll, 100)

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll)
  }, [])

  useEffect(() => {
    if (!user_id || !loggedIn) return
    const fetchUserInfo = async () => {
      const requestOptions = {
        method: 'GET',
        url: `v2/users/info`,
      }
      setLoading(true)
      try {
        const userInfo = await request(
          requestOptions,
          authURL(window.location.host)
        )
        dispatch({ type: SET_USER_INFO, userInfo: userInfo.data })
        if (userInfo && userInfo.data)
          setModalOpen(!userInfo.data.has_signed_current_eula)
        return setLoading(false)
      } catch (error) {
        setLoading(false)
        console.warn(error)
      }
    }

    fetchUserInfo()
  }, [user_id, loggedIn])

  useEffect(() => {
    checkStorage()
  }, [])

  const handleOnClose = () => {
    setModalOpen(false)
    sessionStorage.clear()
    dispatch({ type: RESET_LOGIN })
  }
  const domain = user_email?.toLowerCase().split('@')[1]
  const isRiskReconUser =
    domain === 'riskrecon.com' || domain === 'mastercard.com'
  if (
    user_id &&
    !loading &&
    modalOpen &&
    !has_signed_current_eula &&
    !pathname.includes('eula') &&
    !isRiskReconUser
  )
    if (
      user_id &&
      !loading &&
      modalOpen &&
      !has_signed_current_eula &&
      !pathname.includes('eula')
    )
      return <EulaAcceptModal onClose={handleOnClose} />
  if (user_id && !dismissed_vendor_portal_intro && !hasDismissedModal)
    return <IntroductionModal setHasDismissedModal={setHasDismissedModal} />
  if (loading) {
    return <LoaderIcon />
  }

  if (currentEnv === 'production') {
    FullStory('setIdentity', {
      uid: user_id,
      properties: {
        customer_id: customer_id,
        customer_name: customer_name,
      },
    })
  }

  return loggedIn ? <AuthRoutes /> : <UnauthRoutes />
}

export default App
