import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from '../hooks/useSearchParams'
import GlobeSVG from '../assets/svg/GlobeSVG'
import { NavItemDropDown } from '../components/NavItemDropDown'
import { supportedLanguages } from '../constants/SupportedLanguages'
import './LanguageDropdown.scss'

export const LanguageDropdown = () => {
  const { i18n } = useTranslation()
  const query = useSearchParams()

  const isI18nEnabled = query.get('enableLocalization') === '1'

  const currentLanguageLabel = useMemo(() => {
    return supportedLanguages.find?.((lang) => lang.isoCode === i18n.language)
      ?.label
  }, [i18n.language])

  const updateLanguange = (isoCode) => {
    i18n.changeLanguage(isoCode)
  }

  if (!isI18nEnabled) return <></>

  return (
    <div className='language-dropdown'>
      <GlobeSVG />
      <NavItemDropDown title={currentLanguageLabel}>
        {supportedLanguages?.map?.((lang) => (
          <button
            key={lang.isoCode}
            className='hover-nav'
            onClick={() => updateLanguange(lang.isoCode)}
          >
            {lang.name}
          </button>
        ))}
      </NavItemDropDown>
    </div>
  )
}
